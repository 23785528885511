import { __decorate } from "tslib";
import { Component, Vue, Watch } from 'vue-property-decorator';
import { alunoStore, cityStore, companyConfiguracaoDiaVencimentoParcelaStore, companyConfiguracaoStore, contratoStore, cursoStore, enderecoStore, financeiroCategoriaStore, livroStore, mainStore, pontoParadaStore, prospexStore, turmaStore, userActionStore, userStore, userToolStore, parceriaCupomHistoricoStore, } from '@/store';
import _ from 'lodash';
import { apiUrl } from '@/env';
import { newAluno } from '@/interfaces/aluno';
import { validateCPF } from '@/utils';
import ClickableCard from '@/components/ClickableCard.vue';
import ContratoAdicionarEstagioComponent from '@/components/ContratoAdicionarEstagioComponent.vue';
import ContratoAlterarEstagioComponent from '@/components/ContratoAlterarEstagioComponent.vue';
import ContratoCancelarComponent from '@/components/ContratoCancelarComponent.vue';
import ContratoExcluirComponent from '@/components/ContratoExcluirComponent.vue';
import ContratoLivroExcluirComponent from '@/components/ContratoLivroExcluirComponent.vue';
import ContratoTrancarComponent from '@/components/ContratoTrancarComponent.vue';
import CrudArrayComponent from '@/components/CrudArrayComponent.vue';
import DateFieldComponent from '@/components/DateFieldComponent.vue';
import FinanceiroAlunoComponent from '@/components/FinanceiroAlunoComponent.vue';
import LogShowComponent from '@/components/LogShowComponent.vue';
import OcorrenciaComponent from '@/components/OcorrenciaComponent.vue';
import OpenNewTabComponent from '@/components/OpenNewTabComponent.vue';
import PedagogicoAlunoComponent from '@/components/PedagogicoAlunoComponent.vue';
import SaveButton from '@/components/SaveButton.vue';
import SearchCityComponent from '@/components/SearchCityComponent.vue';
import SearchEntidadeComponent from '@/components/SearchEntidadeComponent.vue';
import TurmaAdicionarAlunoComponent from '@/components/TurmaAdicionarAlunoComponent.vue';
import TurmaEvadirAlunoComponent from '@/components/TurmaEvadirAlunoComponent.vue';
import UploadFilesComponent from '@/components/UploadFilesComponent.vue';
import TextExpandableComponent from '@/components/TextExpandableComponent.vue';
import SolicitarLivroParaFranchiseComponent from '@/components/SolicitarLivroParaFranchiseComponent.vue';
import FranchisePreInvoiceVisualizaComponent from '@/components/FranchisePreInvoiceVisualizaComponent.vue';
import LivroSolicitadoDiretoFranchiseButton from '@/components/LivroSolicitadoDiretoFranchiseButton.vue';
import EntregarLivroButton from '@/components/EntregarLivroButton.vue';
import GerarFinanceiroMaterialDidaticoDialog from '@/components/GerarFinanceiroMaterialDidaticoDialog.vue';
import ContratoCriarComponent from '@/components/ContratoCriarComponent.vue';
import AulaLocalShowButton from '@/components/AulaLocalShowButton.vue';
import { queryNew } from '@/interfaces/query';
import { regexValidacaoEmail } from '@/utils';
import { ValidationObserver, ValidationProvider, Validator, } from 'vee-validate';
const dict = {
    custom: {
        password: {
            required: 'Senha deve ser preenchido!',
        },
        password_confirmation: {
            required: 'Confirmar Senha deve ser preenchido!',
            confirmed: 'As senhas devem ser iguais!',
        },
    },
};
Validator.localize('pt', dict);
Validator.extend('required', {
    getMessage: (field) => `${field} deve ser preenchido!`,
    validate: async (value) => {
        return !!value;
    },
});
Validator.extend('nomeCompleto', {
    getMessage: (field) => `${field} deve ser preenchido Completo`,
    validate: async (value) => {
        if (!value) {
            return true;
        }
        return value && value.trim().split(' ').length > 1;
    },
});
Validator.extend('email', {
    getMessage: (field) => `${field} deve ser um email correto!`,
    validate: async (email) => {
        const re = regexValidacaoEmail();
        // const re =
        //   /^(([^<>()[\]\\.,;:\s@\']+(\.[^<>()[\]\\.,;:\s@\']+)*)|(\'.+\'))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return !!re.test(email);
    },
});
Validator.extend('cpf', {
    getMessage: (field) => `${field} deve ser um CPF válido!`,
    validate: async (cpf) => {
        return !!cpf && !!validateCPF(cpf);
    },
});
let Create = class Create extends Vue {
    constructor() {
        super(...arguments);
        this.pontosParadaHeaders = [
            { sortable: true },
            { text: 'Bairro', value: 'bairro' },
            { text: 'Logradouro', value: 'logradouro' },
            { text: 'Número', value: 'numero' },
            {
                text: 'Ponto de busca',
                value: 'ponto_busca',
            },
            {
                text: 'Ponto de entrega',
                value: 'ponto_entrega',
            },
        ];
        this.atendimentoHeaders = [
            { text: 'Responsável', value: 'responsavel.full_name' },
            { text: 'Data/Hora', value: 'data_hora_agendamento' },
            { text: 'Status', value: 'tipo_atendimento' },
            { text: 'Observações', value: 'observacao' },
        ];
        this.crudArrayComponentHeader = [
            {
                text: 'Telefone',
                sortable: true,
                value: 'phone',
                type: 'varchar',
                isPhone: true,
                align: 'left',
            },
            {
                text: 'Operadora',
                sortable: true,
                value: 'operadora',
                type: 'numeric',
                align: 'left',
            },
            {
                text: 'Observação',
                sortable: true,
                value: 'observation',
                type: 'varchar',
                align: 'left',
            },
            {
                text: 'Ações',
                sortable: true,
                value: 'actions',
                align: 'left',
            },
        ];
        this.materialDidaticoHeaders = [
            {
                text: 'Parcela',
                value: 'parcela',
                align: 'left',
                filterable: true,
            },
            {
                text: 'Valor',
                value: 'valor',
                align: 'left',
                filterable: true,
            },
            {
                text: 'Vencimento',
                value: 'vencimento',
                align: 'left',
                filterable: true,
            },
            {
                text: '',
                value: 'actions',
                align: 'left',
                filterable: true,
            },
        ];
        this.servicosPrestadosHeaders = [
            {
                text: 'Livro',
                value: 'livro_id',
                align: 'left',
                filterable: true,
            },
            {
                text: 'Turma',
                value: 'turma',
                align: 'left',
                filterable: true,
            },
            {
                text: '',
                value: 'actions',
                align: 'right',
            },
            {
                text: '',
                value: 'print_btn',
                align: 'right',
            },
        ];
        this.matriculaFinanceiroHeaders = [
            {
                text: 'Categoria',
                value: 'financeiro_categoria_id',
                align: 'left',
                filterable: true,
            },
            {
                text: 'Valor Integral',
                value: 'valor_integral',
                align: 'left',
                filterable: true,
            },
            {
                text: 'Desconto (%)',
                value: 'desconto_percentual',
                align: 'left',
                filterable: true,
            },
            {
                text: 'Valor com desconto',
                value: 'valor_com_desconto',
                align: 'left',
                filterable: true,
            },
            {
                text: 'Quantidade de parcelas',
                value: 'numero_parcelas',
                align: 'left',
                filterable: true,
            },
            {
                text: 'Valor da parcela integral',
                value: 'valor_parcela_integral',
                align: 'left',
                filterable: true,
            },
            {
                text: 'Valor da parcela com desconto',
                value: 'valor_parcela_desconto',
                align: 'left',
                filterable: true,
            },
        ];
        this.transporteHeaders = [
            {
                text: 'Dia',
                value: 'dia_semana',
                align: 'left',
                filterable: true,
            },
            {
                text: 'Motorista',
                value: 'motorista_nome',
                align: 'left',
                filterable: true,
            },
            {
                text: 'Veiculo',
                value: 'veiculo_observacao',
                align: 'left',
                filterable: true,
            },
            {
                text: 'Ponto de parada',
                value: 'ponto_parada_observacao',
                align: 'left',
                filterable: true,
            },
        ];
        this.activePanel = [];
        this.aluno_responsavel_financeiro = true;
        this.alunoIdade = null;
        this.atendimentoItems = [];
        this.atualizarDadosProspex = true;
        this.backupContratoVigenciaInicio = null;
        this.backupContratoVigenciaFim = null;
        this.backupContratoConsultorSelected = null;
        this.backupContratoMatriculaTipo = null;
        this.backupContratoDuracaoEstagio = null;
        this.cidadeSelected = { codigo_municipio_completo: null };
        this.colocarAlunoTurmaAlunoId = null;
        this.colocarAlunoTurmaContratoId = null;
        this.colocarAlunoTurmaLivro = null;
        this.colocarAlunoTurmaLivroId = null;
        this.colocarAlunoTurmaModalidadeId = null;
        this.colocarAlunoTurmaTurmaAlunoId = null;
        this.colocarAlunoTurmaContratoLivroId = null;
        this.contratoCancelar = null;
        this.contratoExcluir = null;
        this.contratoLivroExcluir = null;
        this.contratoId = 0;
        this.contratoItems = [];
        this.contratoLivroId = 0;
        this.contratoLivroObject = null;
        this.contratoPicker = '';
        this.contratoTrancar = null;
        this.contratoTrancarOperacao = null;
        this.cpfSearchAluno = {};
        this.cpfSearchDialog = false;
        this.crudArrayComponentItems = [];
        this.curso = '';
        this.idade = null;
        this.dataAssinaturaContrato = null;
        this.dialogColocarAlunoTurma = false;
        this.dialogTurmaEvadirAluno = false;
        this.dialogTurmaEvadirAlunoTurmaAlunoId = null;
        this.dialogSolicitarLivroFranchising = false;
        this.dialogContratoAlterarEstagio = false;
        this.dialogContratoAlterarEstagioContratoLivroItem = null;
        this.dialogContratoAlterarEstagioCursoId = null;
        this.dialogContratoAdicionarEstagio = false;
        this.dialogContratoAdicionarEstagioItem = null;
        this.dialogContratoCancelar = false;
        this.dialogContratoExcluir = false;
        this.dialogContratoLivroExcluir = false;
        this.dialogContratoTrancar = false;
        this.preInvoiceId = '';
        this.entityObject = null;
        this.etapaAtual = 1;
        this.id = null;
        this.idioma = [];
        this.indicacaoItems = [];
        this.livroItems = [];
        this.livroSelected = null;
        this.loading = false;
        this.materialDidaticoParcela = [];
        this.modalidadeSelected = [];
        this.modoCriarOcorrencia = false;
        this.modoEdicaoDataAssinaturaContrato = false;
        this.modoEdicaoConsultorContrato = false;
        this.modoEdicaoMatriculaTipo = false;
        this.modoEdicaoDuracaoEstagio = false;
        this.ocorrenciaItems = [];
        this.ocorrenciaUploadFiles = [];
        this.pedagogicoItems = [];
        this.periodoSelected = [];
        this.picker = '';
        this.pontoParadaBuscaId = null;
        this.pontoParadaEntregaId = null;
        this.quantidadeParcelas = 1;
        this.responsavel_picker = '';
        this.responsavelCidadeSelected = { codigo_municipio_completo: null };
        this.saveLoading = false;
        this.search = '';
        this.selectedAlterarEstagioLivro = {};
        this.selectedItem = {};
        this.selectedProspex = null;
        this.servicosPrestadosItems = [];
        this.showActionsButtons = true;
        this.showAddMoreBooks = false;
        this.showCardNovoContrato = false;
        this.showFinanceiroAlunoComponent = true;
        this.showRevisaoMatricula = false;
        this.tab = 0;
        this.transporteItems = [];
        this.url = apiUrl;
        this.userActionIncluirTransporte = false;
        this.valor_material_didatico = 0;
        this.entityAlunoUser = null;
        this.dialogAlterarSenhaAcessoPortal = false;
        this.password1 = '';
        this.password2 = '';
        this.alunoCidadeLoading = true;
        this.responsavelCidadeLoading = true;
        this.modoEdicaoCurso = false;
        this.listaDeCursos = [];
        this.preInvoiceData = null;
        this.dialogSolicitarTransporte = false;
        this.tipoPessoaReponsavel = 'PF' || 'PJ';
        this.contratoDataVencimentoPrimeiraParcelaCurso = null;
        this.turmaAlunoId = null;
        this.alunoNumeroIgual = [];
        this.alunoEmailIgual = [];
        this.editandoData = false;
        this.backupDataCancelamento = null;
        this.menuData = false;
    }
    editarDataCancelamento(contrato) {
        this.editandoData = true;
        this.backupDataCancelamento = contrato.cancelado_data_hora;
    }
    get showForcarEvasao() {
        const forcar_evasao = this.userProfile.company.permite_forcar_evasao_aluno;
        return forcar_evasao;
    }
    get isButtonDisabled() {
        if (this.entityAlunoUser && !this.entityAlunoUser.is_active) {
            return false;
        }
        return (!this.id ||
            !this.allowDelete ||
            (!this.permiteExcluirAlunoFinanceiroContrato && this.contratoItems.length > 0));
    }
    alterarEstagioOptions(item) {
        const livroAtual = _.find(this.livros, (val) => {
            return item.livro_id === val.id;
        });
        return _.filter(this.livros, (val) => {
            return livroAtual.curso.idioma === val.curso.idioma;
        });
    }
    get userActionsAcessoAOLogDoSistema() {
        return userActionStore.acessoAOLogDoSistema;
    }
    get userActionsVisualizarAbaDadosCadastraisAllow() {
        return userActionStore.visualizarAbaDadosCadastraisAllow;
    }
    get isValidCPF() {
        return validateCPF(this.entityObject.aluno_cpf);
    }
    get permiteExcluirAlunoFinanceiroContrato() {
        return userActionStore.excluirAlunoFinanceiroContrato;
    }
    get todayDate() {
        const date = new Date();
        date.setHours(date.getHours() - 3);
        return date;
    }
    get pontoParadaItems() {
        return pontoParadaStore.pontosParada;
    }
    get existeAlunoCPFSearch() {
        if (this.cpfSearchAluno) {
            return Object.keys(this.cpfSearchAluno).length > 0;
        }
        return false;
    }
    get token() {
        return localStorage.getItem('token');
    }
    async validateTelefone(telefone) {
        this.alunoNumeroIgual = await alunoStore.getAlunoByTelefone(telefone);
        if (this.alunoNumeroIgual.length > 0) {
            this.$swal({
                title: 'Número duplicado',
                text: 'Já existe um aluno cadastrado com este número de telefone.',
                icon: 'warning',
                confirmButtonText: 'Entendido'
            });
        }
    }
    async validateEmail(email) {
        this.alunoEmailIgual = await alunoStore.getAlunoByEmail(email);
        if (this.alunoEmailIgual.length > 0) {
            this.$swal({
                title: 'E-mail duplicado',
                text: 'Já existe um aluno cadastrado com este e-mail.',
                icon: 'warning',
                confirmButtonText: 'Entendido'
            });
        }
    }
    get livros() {
        return livroStore.livros;
    }
    async handleCancelarSolicitacaoTransporte(contratoId) {
        this.$swal({
            title: 'Confirma o cancelamento do transporte do aluno?',
            text: '',
            icon: 'warning',
            showCancelButton: true,
            cancelButtonText: 'Cancelar',
            confirmButtonText: 'Sim, eu confirmo!',
        }).then(async (result) => {
            if (result.isConfirmed) {
                const contratoTransporte = {
                    id: contratoId,
                    requisitou_transporte: false,
                    ponto_parada_ida_id: undefined,
                    ponto_parada_volta_id: undefined,
                };
                await contratoStore.contratoAlterarTransporte(contratoTransporte);
                await this.handleCarregaContratoLivro();
            }
        });
    }
    async handleSolicitacaoTransporte(contratoId) {
        this.contratoId = contratoId;
        this.dialogSolicitarTransporte = true;
        await pontoParadaStore.getPontosParada();
    }
    async handleSalvarSolicitacaoTransporte() {
        const contratoTransporte = {
            id: this.contratoId,
            requisitou_transporte: true,
            ponto_parada_ida_id: this.pontoParadaBuscaId,
            ponto_parada_volta_id: this.pontoParadaEntregaId,
        };
        await contratoStore.contratoAlterarTransporte(contratoTransporte);
        await this.handleCarregaContratoLivro();
        this.dialogSolicitarTransporte = false;
    }
    getTotalParcelasMaterialDidatico(items) {
        return _.sumBy(items, (item) => {
            // @ts-ignore
            return item.financeiro_categoria_id ===
                // @ts-ignore
                this.getFinanceiroCategoriaMaterialDidatico104()
                ? // @ts-ignore
                    item.numero_parcelas
                : 0;
        });
    }
    async salvarNovaDataCancelamento(contrato) {
        this.editandoData = false;
        this.contratoId = contrato.id;
        const now = new Date();
        const timeString = now.toTimeString().split(' ')[0];
        const novaData = new Date(`${contrato.cancelado_data_hora}T${timeString}`);
        await contratoStore.updateContrato({
            id: this.contratoId,
            cancelado_data_hora: novaData,
        });
        this.refreshDados();
    }
    cancelarEdicao(contrato) {
        contrato.cancelado_data_hora = this.backupDataCancelamento;
        this.editandoData = false;
    }
    getTotalParcelasCurso(items) {
        return _.sumBy(items, (item) => {
            // @ts-ignore
            return item.financeiro_categoria_id ===
                // @ts-ignore
                this.getFinanceiroCategoriaCurso105()
                ? // @ts-ignore
                    item.numero_parcelas
                : 0;
        });
    }
    async validarPreInvoiceId() {
        this.preInvoiceData = await contratoStore.getMasterPreInvoice({
            preInvoiceId: parseInt(this.preInvoiceId, 0),
        });
    }
    get userProfile() {
        return mainStore.userProfile;
    }
    get userActions() {
        return userActionStore.userActions;
    }
    get userActionsCriarContratoAllow() {
        return userActionStore.criarContratoAllow;
    }
    get userActionsRescindirContratoAllow() {
        return userActionStore.rescindirContratoAllow;
    }
    get userActionsTrancarContratoAllow() {
        return userActionStore.trancarContratoAllow;
    }
    get userActionsExcluirContratoRequestPermission() {
        return userActionStore.excluirContratoRequestPermission;
    }
    get userActionsExcluirContratoAllow() {
        return userActionStore.excluirContratoAllow;
    }
    get userActionsExcluirContratoComMovimentacaoRequestPermission() {
        return userActionStore.excluirContratoComMovimentacaoRequestPermission;
    }
    get userActionsExcluirContratoComMovimentacaoAllow() {
        return userActionStore.excluirContratoComMovimentacaoAllow;
    }
    get userActionsTransferirAlunoTurmaAllow() {
        return userActionStore.transferirAlunoTurma;
    }
    get userActionsEvadirAlunoTurmaAllow() {
        return userActionStore.evadirAlunoTurma;
    }
    get userActionsAdicionarEstagioContratoAllow() {
        return userActionStore.adicionarEstagioContrato;
    }
    get userActionsRemoverAlunoTurmaAllow() {
        return userActionStore.removerAlunoTurma;
    }
    get userActionsEditarContratoAllow() {
        return userActionStore.editarContratoAllow;
    }
    get userActionsEditarCursoAllow() {
        return userActionStore.editarCursoContratoAllow;
    }
    get userActionsVisualizarFinanceiroAllow() {
        return userActionStore.visualizarFinanceiroAllow;
    }
    get userActionsVisualizarTelefonesContatosAllow() {
        return userActionStore.visualizarTelefonesContatosAllow;
    }
    get userActionsVisualizarContratosAllow() {
        return userActionStore.visualizarContratosAllow;
    }
    get userActionsSolicitarLivroFranchisingAllow() {
        return userActionStore.solicitarLivroFranchisingAllow;
    }
    get userActionsSolicitarLivroFranchisingRequestPermission() {
        return userActionStore.solicitarLivroFranchisingRequestPermission;
    }
    get userActionsExcluirEstagioAllow() {
        return userActionStore.excluirEstagioAllow;
    }
    get userActionsAlterarEstagioAllow() {
        return userActionStore.alterarEstagioAllow;
    }
    get userActionsColocarAlunoTurmaAllow() {
        return userActionStore.colocarAlunoTurmaAllow;
    }
    get indicacaoItemsByContrato() {
        return _.groupBy(this.indicacaoItems, 'contrato_id');
    }
    get allowDelete() {
        if (userToolStore.userToolsIndexed.Aluno) {
            return userToolStore.userToolsIndexed.Aluno[0].allow_delete;
        }
    }
    get allowEdit() {
        if (userToolStore.userToolsIndexed.Aluno) {
            return userToolStore.userToolsIndexed.Aluno[0].allow_edit;
        }
    }
    get allowCreate() {
        if (userToolStore.userToolsIndexed.Aluno) {
            return userToolStore.userToolsIndexed.Aluno[0].allow_create;
        }
    }
    get alunos() {
        return alunoStore.alunos;
    }
    openNewTabAluno(id) {
        const routeData = this.$router.resolve({
            name: 'main-alunos-edit',
            params: { id },
        });
        window.open(routeData.href, '_blank');
    }
    get companyConfiguracoes() {
        return companyConfiguracaoStore.companyConfiguracoes;
    }
    get cursosEtapas() {
        return cursoStore.cursosEtapas;
    }
    get FinanceiroCategoria() {
        return financeiroCategoriaStore.FinanceiroCategorias;
    }
    get valorTotalCurso() {
        let valorFinalCurso = 0;
        _.forEach(this.livroItems, (livro) => {
            valorFinalCurso += this.valorCurso(livro);
        });
        return valorFinalCurso;
    }
    getCardSelectedItemsString(selected) {
        if (selected) {
            return selected.join(', ');
        }
        return '';
    }
    get excluirAlunoFinanceiroContrato() {
        return userActionStore.excluirAlunoFinanceiroContrato;
    }
    async excluirAluno() {
        let text = '';
        if (userActionStore.excluirAlunoFinanceiroContrato) {
            text =
                'Isso irá excluir todos os contratos e financeiros do aluno. Não terá como recuperar depois da exclusão.';
        }
        this.$swal({
            title: 'Confirma a exclusão do aluno?',
            text,
            icon: 'warning',
            showCancelButton: true,
            cancelButtonText: 'Cancelar',
            confirmButtonText: 'Sim, eu confirmo!',
        }).then(async (result) => {
            if (result.isConfirmed) {
                if (await alunoStore.deleteAluno(this.id)) {
                    if (this.selectedProspex) {
                        const prospex = await prospexStore.getProspex(this.selectedProspex.id);
                        if (prospex) {
                            await prospexStore.updateProspex({
                                // @ts-ignore
                                id: this.selectedProspex.id,
                                // @ts-ignore
                                status_id: prospex.atendente_id ? 2 : 1,
                            });
                        }
                    }
                    const queryIn = queryNew();
                    await alunoStore.getAlunos(queryIn);
                    this.$router.push({ name: 'main-alunos' });
                }
            }
        });
    }
    async conferirAluno() {
        this.$swal({
            title: 'Confirma a verificação do aluno?',
            text: '',
            icon: 'warning',
            showCancelButton: true,
            cancelButtonText: 'Voltar',
            confirmButtonText: 'Sim, eu confirmo!',
        }).then(async (result) => {
            if (result.isConfirmed) {
                this.entityObject.conferido_em = this.todayDate;
                this.entityObject.conferido_por_id = this.userProfile.id;
                this.submit();
            }
        });
    }
    async onRejectEntregarLivro() {
        this.contratoLivroId = 0;
    }
    async onConfirmEntregarLivro() {
        this.loading = true;
        this.onRejectEntregarLivro();
        this.refreshDados();
        this.loading = false;
    }
    async livroDiretoFranchising(contratoLivro) {
        this.contratoLivroObject = contratoLivro;
        this.dialogSolicitarLivroFranchising = true;
    }
    async habilitarModoEdicaoDataAssinaturaContrato(contrato) {
        this.backupContratoVigenciaInicio = contrato.vigencia_inicio;
        this.backupContratoVigenciaFim = contrato.vigencia_fim;
        this.contratoDataVencimentoPrimeiraParcelaCurso = contrato.vigencia_inicio;
        this.modoEdicaoDataAssinaturaContrato = true;
    }
    async habilitarModoEdicaoConsultorContrato(consultorSelected) {
        this.backupContratoConsultorSelected = consultorSelected;
        this.modoEdicaoConsultorContrato = true;
    }
    async habilitarModoEdicaoMatriculaTipo(matriculaTipo) {
        this.backupContratoMatriculaTipo = matriculaTipo;
        this.modoEdicaoMatriculaTipo = true;
    }
    async habilitarModoEdicaoDuracaoEstagio(duracaoEstagio) {
        this.backupContratoDuracaoEstagio = duracaoEstagio;
        this.modoEdicaoDuracaoEstagio = true;
    }
    async atualizarDataAssinaturaContrato(contrato) {
        this.showFinanceiroAlunoComponent = false;
        contrato.data_primeira_parcela_curso = this.contratoDataVencimentoPrimeiraParcelaCurso;
        contrato.quantidade_meses_por_estagio =
            // @ts-ignore
            this.cursoModalidadeOptions()[contrato.modalidade_id - 1].quantidade_meses_por_estagio;
        await contratoStore.updateContratoVigenciaInicio(contrato);
        await contratoStore.updateContrato({
            id: contrato.id,
            trancado_previsao_retorno: contrato.trancado_previsao_retorno,
        });
        this.refreshDados();
        this.showFinanceiroAlunoComponent = true;
        this.modoEdicaoDataAssinaturaContrato = false;
    }
    async atualizarMatriculaTipo(contrato) {
        await contratoStore.updateContrato(contrato);
        this.modoEdicaoMatriculaTipo = false;
    }
    async atualizarDuracaoEstagio(contrato) {
        await contratoStore.updateContrato(contrato);
        this.modoEdicaoDuracaoEstagio = false;
    }
    async atualizarConsultorContrato(contrato) {
        this.showFinanceiroAlunoComponent = false;
        contrato.consultor_id = contrato.consultorSelected.id;
        await contratoStore.updateContrato(contrato);
        this.showFinanceiroAlunoComponent = true;
        this.modoEdicaoConsultorContrato = false;
    }
    async cancelarEdicaoDataAssinaturaContrato(contrato) {
        contrato.vigencia_inicio = this.backupContratoVigenciaInicio;
        contrato.vigencia_fim = this.backupContratoVigenciaFim;
        this.modoEdicaoDataAssinaturaContrato = false;
    }
    async cancelarEdicaoConsultorContrato(contrato) {
        contrato.consultorSelected = this.backupContratoConsultorSelected;
        this.modoEdicaoConsultorContrato = false;
    }
    async cancelarEdicaoMatriculaTipo(contrato) {
        contrato.matricula_tipo_id = this.backupContratoMatriculaTipo;
        this.modoEdicaoMatriculaTipo = false;
    }
    async cancelarEdicaoDuracaoEstagio(contrato) {
        contrato.quantidade_meses_por_estagio = this.backupContratoDuracaoEstagio;
        this.modoEdicaoDuracaoEstagio = false;
    }
    async onCancel() {
        this.loading = true;
        this.dialogContratoLivroExcluir = false;
        this.dialogContratoAdicionarEstagio = false;
        this.dialogContratoAlterarEstagio = false;
        this.dialogContratoCancelar = false;
        this.dialogContratoExcluir = false;
        this.dialogContratoTrancar = false;
        this.dialogTurmaEvadirAluno = false;
        this.contratoTrancarOperacao = null;
        this.dialogContratoAlterarEstagioContratoLivroItem = null;
        this.dialogContratoAlterarEstagioCursoId = null;
        this.refreshDados();
        this.loading = false;
    }
    async onCancelNew() {
        this.loading = true;
        this.dialogContratoAdicionarEstagio = false;
        this.dialogContratoAlterarEstagio = false;
        this.dialogContratoCancelar = false;
        this.dialogContratoExcluir = false;
        this.dialogContratoTrancar = false;
        this.dialogTurmaEvadirAluno = false;
        this.dialogSolicitarLivroFranchising = false;
        this.contratoTrancarOperacao = null;
        this.contratoLivroObject = null;
        this.dialogContratoAlterarEstagioContratoLivroItem = null;
        this.dialogContratoAlterarEstagioCursoId = null;
        this.loading = false;
    }
    async onConfirm() {
        this.loading = true;
        this.dialogContratoAdicionarEstagio = false;
        this.dialogContratoAlterarEstagio = false;
        this.dialogContratoCancelar = false;
        this.dialogContratoExcluir = false;
        this.dialogContratoTrancar = false;
        this.dialogTurmaEvadirAluno = false;
        this.dialogSolicitarLivroFranchising = false;
        this.contratoTrancarOperacao = null;
        this.contratoLivroObject = null;
        this.dialogContratoAlterarEstagioContratoLivroItem = null;
        this.dialogContratoAlterarEstagioCursoId = null;
        this.contratoLivroId = null;
        this.refreshDados();
        this.loading = false;
    }
    async refreshDados() {
        this.loading = true;
        this.atualizarDadosProspex = false;
        this.contratoItems = await contratoStore.getContratos(this.id);
        for (const item of this.contratoItems) {
            item.turmaAluno = await turmaStore.getTurmaAlunos({
                contrato_id: item.id,
                aluno_id: item.aluno_id,
            });
            if (item.consultor) {
                item.consultorSelected = {
                    tipo: 'Colaborador',
                    id: item.consultor.id,
                    nome: item.consultor.full_name,
                };
            }
            else {
                item.consultorSelected = null;
            }
            if (item.ponto_parada_ida_id) {
                item.ponto_parada_ida = await this.getPontoParada(item.ponto_parada_ida_id);
            }
            if (item.ponto_parada_volta_id) {
                item.ponto_parada_volta = await this.getPontoParada(item.ponto_parada_volta_id);
            }
            item.contrato_livro = _.orderBy(item.contrato_livro, ['id']);
        }
        this.dialogColocarAlunoTurma = false;
        this.loading = false;
    }
    onClickEditTransportePontoParadaOption(value, item, pontoParada, tipo) {
        switch (tipo) {
            case 'ida':
                item.ponto_parada_ida_id = pontoParada.id;
                break;
            case 'volta':
                item.ponto_parada_volta_id = pontoParada.id;
        }
    }
    valorCurso(livro) {
        const precificacao = this.companyConfiguracoes;
        switch (this.modalidadeSelected[0]) {
            case 'Regular':
                // @ts-ignore
                return precificacao.preco_regular;
            case 'Intensivo':
                // @ts-ignore
                return precificacao.preco_intensivo;
            case 'VIP':
                // @ts-ignore
                return precificacao.preco_vip;
            case 'Executive':
                // @ts-ignore
                return precificacao.preco_executive;
        }
    }
    async onChangeProspex(val) {
        if (val.id && this.atualizarDadosProspex) {
            const prospex = await prospexStore.getProspex(val.id);
            this.selectedProspex.parceria_cupom_id = prospex.parceria_cupom_id;
            this.entityObject.prospex_id = prospex.id;
            this.idade = prospex.idade;
            this.entityObject.aluno_bairro = prospex.bairro;
            this.entityObject.aluno_contatos = [];
            if (prospex.celular) {
                this.entityObject.aluno_contatos.push({
                    phone: prospex.celular,
                    operadora: prospex.operadora,
                    observation: 'Celular do Aluno',
                });
            }
            this.entityObject.aluno_codigo_municipio_completo =
                prospex.codigo_municipio_completo;
            // @ts-ignore
            this.idioma[0] = prospex.curso_interesse[0];
            // @ts-ignore
            this.dias_preferencia = prospex.dias_preferencia;
            this.entityObject.aluno_email = prospex.email;
            if (prospex.fone_fixo) {
                this.entityObject.aluno_contatos.push({
                    phone: prospex.fone_fixo,
                    operadora: '',
                    observation: 'Fone fixo do Aluno',
                });
            }
            this.entityObject.aluno_nome = prospex.nome;
            if (prospex.responsavel) {
                this.entityObject.aluno_responsavel_financeiro = false;
                this.entityObject.responsavel_nome = prospex.responsavel;
            }
            if (prospex.codigo_municipio_completo) {
                this.updateCity(prospex, null);
            }
        }
    }
    async update_responsavel_endereco() {
        this.entityObject.responsavel_cep = this.entityObject.aluno_cep;
        this.entityObject.responsavel_bairro = this.entityObject.aluno_bairro;
        this.entityObject.responsavel_rua = this.entityObject.aluno_rua;
        this.entityObject.responsavel_numero = this.entityObject.aluno_numero;
        this.entityObject.responsavel_complemento = this.entityObject.aluno_complemento;
        this.entityObject.responsavel_codigo_municipio_completo = this.entityObject.aluno_codigo_municipio_completo;
        this.responsavelCidadeSelected.codigo_municipio_completo = this.entityObject.aluno_codigo_municipio_completo;
        this.entityObject = { ...this.entityObject };
    }
    async updateCity(val, oldVal) {
        if (val) {
            if (val.codigo_municipio_completo != oldVal?.codigo_municipio_completo) {
                this.cidadeSelected = await cityStore.getCityByCodigoMunicipio(val.codigo_municipio_completo);
                // @ts-ignore
                this.cidadeSelected.name =
                    // @ts-ignore
                    this.cidadeSelected.nome_municipio +
                        ' - ' +
                        // @ts-ignore
                        this.cidadeSelected.nome_uf;
                // @ts-ignore
                this.cidadeSelected = {
                    // @ts-ignore
                    nome_municipio: this.cidadeSelected.nome_municipio,
                    // @ts-ignore
                    name: this.cidadeSelected.name,
                    // @ts-ignore
                    nome_uf: this.cidadeSelected.nome_uf,
                    codigo_municipio_completo: this.cidadeSelected
                        .codigo_municipio_completo,
                };
            }
        }
    }
    async onChangeEntityObjectAlunoCpf(val, olVal) {
        if (val) {
            if (val.length === 14 && !this.id) {
                const aluno = await alunoStore.getAlunoByCPF(val);
                if (aluno) {
                    this.cpfSearchAluno = aluno;
                    if (aluno.aluno_nome.toLowerCase() !==
                        this.entityObject.aluno_nome.toLowerCase()) {
                        this.entityObject.aluno_cpf = '';
                    }
                    this.cpfSearchDialog = true;
                }
                else {
                    this.cpfSearchAluno = {};
                }
            }
        }
    }
    async onVisualizarCPFSearched() {
        // @ts-ignore
        const id = this.cpfSearchAluno.id;
        // @ts-ignore
        await this.$router.push({ name: 'main-alunos-edit', params: { id } });
        // @ts-ignore
        this.$router.go(0);
        this.cpfSearchDialog = false;
    }
    async checkArray(array, value) {
        if (array.indexOf(value) < 0) {
            array.push(value);
        }
        else {
            array.splice(array.indexOf(value), 1);
        }
    }
    async onChangeCep(val) {
        if (val && !this.loading) {
            if (val.length === 9) {
                val = val.replace('-', '');
                const endereco = await enderecoStore.getEndereco(val);
                // @ts-ignore
                if (endereco.erro) {
                    return;
                }
                // @ts-ignore
                if (endereco.bairro) {
                    // @ts-ignore
                    this.entityObject.aluno_bairro = endereco.bairro;
                }
                // @ts-ignore
                if (endereco.logradouro) {
                    // @ts-ignore
                    this.entityObject.aluno_rua = endereco.logradouro;
                }
                if (!this.loading) {
                    this.cidadeSelected = {
                        // @ts-ignore
                        name: endereco.localidade + ' - ' + endereco.uf,
                        // @ts-ignore
                        nome_municipio: endereco.localidade,
                        // @ts-ignore
                        nome_uf: endereco.uf,
                        // @ts-ignore
                        codigo_municipio_completo: endereco.ibge,
                    };
                }
                this.alunoCidadeLoading = true;
                this.$nextTick(() => {
                    this.alunoCidadeLoading = false;
                });
            }
        }
    }
    async onChangeAlunoDataNascimento(val) {
        // @ts-ignore
        this.alunoIdade = this.getAge(val + ' EDT ');
        if (this.alunoIdade < 18) {
            this.entityObject.aluno_responsavel_financeiro = false;
        }
    }
    async onChangeResponsavelCep(val) {
        if (val && !this.loading) {
            if (val.length === 9) {
                val = val.replace('-', '');
                const endereco = await enderecoStore.getEndereco(val);
                // @ts-ignore
                if (endereco.erro) {
                    return;
                }
                // @ts-ignore
                if (endereco.bairro) {
                    // @ts-ignore
                    this.entityObject.responsavel_bairro = endereco.bairro;
                }
                // @ts-ignore
                if (endereco.logradouro) {
                    // @ts-ignore
                    this.entityObject.responsavel_rua = endereco.logradouro;
                }
                if (!this.loading) {
                    this.responsavelCidadeSelected = {
                        // @ts-ignore
                        name: endereco.localidade + ' - ' + endereco.uf,
                        // @ts-ignore
                        nome_municipio: endereco.localidade,
                        // @ts-ignore
                        nome_uf: endereco.uf,
                        // @ts-ignore
                        codigo_municipio_completo: endereco.ibge,
                    };
                }
                this.responsavelCidadeLoading = true;
                this.$nextTick(() => {
                    this.responsavelCidadeLoading = false;
                });
            }
        }
    }
    async cancelarContrato(contrato) {
        this.contratoCancelar = contrato;
        this.dialogContratoCancelar = true;
    }
    async excluirContrato(contrato) {
        this.contratoExcluir = contrato;
        this.dialogContratoExcluir = true;
    }
    async excluirContratoLivro(contrato_livro) {
        this.contratoLivroExcluir = contrato_livro;
        this.dialogContratoLivroExcluir = true;
    }
    async trancarContrato(contrato) {
        this.contratoTrancar = contrato;
        this.dialogContratoTrancar = true;
        if (contrato.trancado_data_hora) {
            this.contratoTrancarOperacao = 2;
        }
        else {
            this.contratoTrancarOperacao = 1;
        }
    }
    alterarEstagioContrato(item, cursoId) {
        this.dialogContratoAlterarEstagioContratoLivroItem = item;
        this.dialogContratoAlterarEstagioCursoId = cursoId;
        this.dialogContratoAlterarEstagio = true;
    }
    adicionarEstagioContrato(item) {
        this.dialogContratoAdicionarEstagioItem = item;
        this.dialogContratoAdicionarEstagio = true;
    }
    async rematriculou(contratoId) {
        this.$swal({
            title: 'Deseja marcar como rematriculado?',
            text: '',
            icon: 'warning',
            showCancelButton: true,
            cancelButtonText: 'Voltar',
            confirmButtonText: 'Sim, eu confirmo!',
        }).then(async (result) => {
            if (result.isConfirmed) {
                await contratoStore.contratoRematriculou(contratoId);
                await this.handleCarregaContratoLivro();
            }
        });
    }
    async naoRematriculou(contratoId) {
        this.$swal({
            title: 'Deseja confirmar com não rematriculado?',
            text: 'É recomendável colocar como não rematriculado após 6 meses.',
            icon: 'warning',
            showCancelButton: true,
            cancelButtonText: 'Voltar',
            confirmButtonText: 'Sim, eu confirmo!',
        }).then(async (result) => {
            if (result.isConfirmed) {
                await contratoStore.contratoNaoRematriculou(contratoId);
                await this.handleCarregaContratoLivro();
            }
        });
    }
    async retornarParaFinalizado(contratoId) {
        this.$swal({
            title: 'Deseja retornar o status para finalizado?',
            text: 'Selecione apenas caso tenha marcado por engano, e ainda fará tentativa de rematrícula.',
            icon: 'warning',
            showCancelButton: true,
            cancelButtonText: 'Voltar',
            confirmButtonText: 'Sim, eu confirmo!',
        }).then(async (result) => {
            if (result.isConfirmed) {
                await contratoStore.contratoFinalizou(contratoId);
                await this.handleCarregaContratoLivro();
            }
        });
    }
    async evadirAluno(turmaAlunoId) {
        this.dialogTurmaEvadirAlunoTurmaAlunoId = turmaAlunoId;
        this.dialogTurmaEvadirAluno = true;
    }
    async getPontoParada(id) {
        const ponto = await pontoParadaStore.getPontoParada(id);
        if (ponto) {
            return ponto;
        }
    }
    async colocarAlunoTurma(contratoId, livroId, livro, contratoLivroId, modalidadeId, turmaAlunoId = null) {
        if (!turmaAlunoId) {
            const contrato = _.find(this.contratoItems, (item) => item.id == contratoId);
            const turmaAluno = _.findLast(contrato.turmaAluno, (item) => item.contrato_livro_id == contratoLivroId);
            turmaAlunoId = turmaAluno ? turmaAluno.id : null;
        }
        this.colocarAlunoTurmaLivroId = livroId;
        this.colocarAlunoTurmaLivro = livro;
        this.colocarAlunoTurmaContratoId = contratoId;
        this.colocarAlunoTurmaAlunoId = this.id;
        this.colocarAlunoTurmaModalidadeId = modalidadeId;
        this.colocarAlunoTurmaTurmaAlunoId = turmaAlunoId; // 1 - Colocar, 2 - Transferencia
        this.colocarAlunoTurmaContratoLivroId = contratoLivroId;
        this.dialogColocarAlunoTurma = true;
    }
    async removerDaTurma(id) {
        this.$swal({
            title: 'Confirma a remoção do aluno dessa turma?',
            text: 'As notas e as frequências do aluno nessa turma serão excluidas.',
            icon: 'warning',
            showCancelButton: true,
            cancelButtonText: 'Voltar',
            confirmButtonText: 'Sim, eu confirmo!',
        }).then(async (result) => {
            if (result.isConfirmed) {
                this.loading = true;
                await turmaStore.deleteTurmaAluno(id);
                this.refreshDados();
                this.loading = false;
            }
        });
    }
    async openProspex(item) {
        // @ts-ignore
        this.$router.push({
            name: 'main-prospexs-edit',
            params: { id: item.id },
        });
    }
    async onChangeContratoPicker(val) {
        if (val) {
            if (val.length === 10) {
                const vals = val.split('-');
                val = vals[2] + '/' + vals[1] + '/' + vals[0];
                this.dataAssinaturaContrato = val;
            }
        }
    }
    async onChangeContrato(val) {
        if (val) {
            if (val.length === 10) {
                this.dataAssinaturaContrato = val;
                const vals = val.split('/');
                val = vals[2] + '-' + vals[1] + '-' + vals[0];
                this.contratoPicker = val;
            }
        }
    }
    getTurmaByServicoPrestado(contrato, contrato_livro_id) {
        return _(contrato)
            .filter((i) => i.contrato_livro_id === contrato_livro_id)
            .orderBy('id')
            .value();
    }
    getTurmaByServicoPrestadoVigente(contrato, contrato_livro_id) {
        return _(contrato)
            .filter((i) => i.contrato_livro_id === contrato_livro_id &&
            [1, 7, 8].includes(i.status_do_aluno_na_turma_id))
            .orderBy('id')
            .value();
    }
    async onChangeNascimentoPicker(val) {
        if (val) {
            if (val.length === 10) {
                const vals = val.split('-');
                val = vals[2] + '/' + vals[1] + '/' + vals[0];
                this.entityObject.aluno_data_nascimento = val;
            }
        }
    }
    async onChangeNascimento(val) {
        if (val) {
            if (val.length === 10) {
                this.entityObject.aluno_data_nascimento = val;
                const vals = val.split('/');
                val = vals[2] + '-' + vals[1] + '-' + vals[0];
                this.picker = val;
            }
        }
    }
    async onChangeResponsavelNascimentoPicker(val) {
        if (val) {
            if (val.length === 10) {
                const vals = val.split('-');
                val = vals[2] + '/' + vals[1] + '/' + vals[0];
                // @ts-ignore
                this.responsavel_data_nascimento = val;
                this.entityObject.responsavel_data_nascimento = val;
            }
        }
    }
    async onChangeResponsavelNascimento(val) {
        if (val) {
            if (val.length === 10) {
                this.entityObject.responsavel_data_nascimento = val;
                const vals = val.split('/');
                val = vals[2] + '-' + vals[1] + '-' + vals[0];
                this.responsavel_picker = val;
            }
        }
    }
    habilitarModoEdicaoCurso() {
        this.modoEdicaoCurso = true;
        // Carregar a lista de cursos se necessário
        if (this.listaDeCursos.length === 0) {
            this.carregarCursos();
        }
    }
    // Função para cancelar a edição
    cancelarEdicaoCurso() {
        this.modoEdicaoCurso = false;
        // Redefinir o curso_id para o valor original se necessário
    }
    async carregarCursos() {
        const response = await cursoStore.getContratoCursos();
        this.listaDeCursos = response;
    }
    async updateCurso(contrato) {
        const cursoId = Number(contrato.curso_id);
        await contratoStore.updateCursoId({
            contratoId: contrato.id,
            cursoId,
        });
    }
    async voltar() {
        await this.$router.push({ name: 'main-alunos' });
    }
    async submit() {
        this.saveLoading = true;
        const isValid = await this.$refs.observer.validate();
        const isValidCidadeAluno = await this.$refs.cidadeAlunoComponent.validateField();
        if (!isValid || !isValidCidadeAluno) {
            this.saveLoading = false;
            // @ts-ignore
            this.$refs.saveButton.changeLoadingStatus(false);
            return;
        }
        if (!this.selectedProspex && !this.userProfile.company.permite_inserir_aluno_sem_prospex && !this.entityObject.aluno_nome) {
            mainStore.addErrorToasted({
                // @ts-ignore
                toasted: this.$toasted,
                errors: ['Não é permitido criar um aluno sem Prospex selecionado.'],
            });
            this.saveLoading = false;
            // @ts-ignore
            this.$refs.saveButton.changeLoadingStatus(false);
            return;
        }
        // this.entityObject.matricula_tipo_id = this.matriculaTipo;
        const tempEntityObject = { ...this.entityObject };
        // @ts-ignore
        tempEntityObject.responsavel_celular = this.entityObject.responsavel_celular
            ? parseInt(this.entityObject.responsavel_celular.replace(/[^0-9]/g, ''), 0)
            : null;
        // @ts-ignore
        tempEntityObject.aluno_celular = this.entityObject.aluno_celular
            ? parseInt(this.entityObject.aluno_celular.replace(/[^0-9]/g, ''), 0)
            : null;
        tempEntityObject.codigo_municipio_completo = this.cidadeSelected.codigo_municipio_completo;
        tempEntityObject.aluno_cpf = this.entityObject.aluno_cpf.replace(/[^0-9]/g, '');
        tempEntityObject.aluno_codigo_municipio_completo = this.cidadeSelected.codigo_municipio_completo;
        tempEntityObject.responsavel_codigo_municipio_completo = this.responsavelCidadeSelected.codigo_municipio_completo;
        if (this.entityObject.responsavel_cpf) {
            tempEntityObject.responsavel_cpf = this.entityObject.responsavel_cpf.replace(/[^0-9]/g, '');
        }
        if (this.selectedProspex) {
            tempEntityObject.prospex_id = this.selectedProspex.id;
        }
        if (this.tipoPessoaReponsavel === 'PF') {
            tempEntityObject.responsavel_cnpj = null;
            tempEntityObject.responsavel_razao_social = null;
            tempEntityObject.responsavel_inscricao_estadual = null;
        }
        else if (this.tipoPessoaReponsavel === 'PJ') {
            tempEntityObject.responsavel_nome = null;
            tempEntityObject.responsavel_data_nascimento = null;
            tempEntityObject.responsavel_cpf = null;
            tempEntityObject.responsavel_rne = null;
            tempEntityObject.responsavel_rg = null;
            tempEntityObject.responsavel_orgao_emissor = null;
            tempEntityObject.responsavel_sexo = null;
            tempEntityObject.responsavel_ocupacao = null;
            tempEntityObject.responsavel_email = null;
            tempEntityObject.responsavel_parentesco = null;
            tempEntityObject.responsavel_pedagogico_nome = null;
            tempEntityObject.responsavel_pedagogico_telefone = null;
            tempEntityObject.responsavel_pedagogico_parentesco = null;
        }
        if (this.id) {
            await alunoStore.updateAluno(tempEntityObject);
        }
        else {
            const result = await alunoStore.createAluno(this.entityObject);
            this.saveLoading = false;
            // @ts-ignore
            await this.$refs.saveButton.changeLoadingStatus(false);
            if (!result) {
                this.$swal({
                    title: 'Cupom já utilizado',
                    text: 'Este cupom já está vinculado a outro aluno e não pode ser reutilizado.',
                    icon: 'error',
                });
                return;
            }
            if (this.selectedProspex) {
                await prospexStore.updateProspex({
                    // @ts-ignore
                    id: this.selectedProspex.id,
                    // @ts-ignore
                    status_id: 3,
                });
                parceriaCupomHistoricoStore.createParceriaCupomHistorico({
                    parceria_cupom_id: this.selectedProspex.parceria_cupom_id,
                    status_id: 13,
                    agendamento: null,
                    observacao: '',
                });
            }
            this.id = result;
            this.entityObject.id = result;
            this.tab = 3;
            window.history.pushState('page2', 'Title', `/main/Aluno/aluno/edit/${this.id}`);
            this.entityAlunoUser = await userStore.getUserByAlunoId(this.id);
        }
    }
    routeEdit(id) {
        this.$router.push({ name: 'main-company-companies-edit', params: { id } });
    }
    async imprimirTermoDesistencia(item) {
        const bytes = await contratoStore.imprimirTermoDesistencia(item.id);
        const w = window.open();
        w.document.write(bytes);
        setTimeout(() => {
            w.print();
        }, 1000);
    }
    async imprimirTermoTrancamento(item) {
        const bytes = await contratoStore.imprimirTermoTrancamento(item.id);
        const w = window.open();
        w.document.write(bytes);
        setTimeout(() => {
            w.print();
        }, 1000);
    }
    async imprimirContrato(item) {
        const bytes = await alunoStore.getAlunoContratoPDF(item.id);
        const w = window.open();
        w.document.write(bytes[0]);
        w.document.write(bytes[1]);
        setTimeout(() => {
            w.print();
        }, 1000);
    }
    async imprimirCarne(contrato_livro_id, aluno_id, tipo) {
        const html = await alunoStore.getServicoCarne({
            contratoLivroId: contrato_livro_id,
            alunoId: aluno_id,
            tipo,
        });
        if (html) {
            const w = window.open();
            w.document.write(html);
        }
    }
    showLog(ref) {
        const form = ref;
        form.show();
    }
    showLogContrato(ref, id) {
        const form = ref;
        this.contratoId = id;
        form.show(id);
    }
    async ativaSalvar() {
        this.showActionsButtons = true;
    }
    async desativaSalvar() {
        this.showActionsButtons = false;
    }
    // public async onClickExcluirEstagio(item) {
    //   this.$swal({
    //     title: 'Confirma a exclusão do estágio?',
    //     text: 'Será efetuada a exclusão do estágio e dos financeiro atrelados ao mesmo!',
    //     icon: 'warning',
    //     showCancelButton: true,
    //     cancelButtonText: 'Voltar',
    //     confirmButtonText: 'Sim, eu confirmo!',
    //   }).then(async (result) => {
    //     if (result.isConfirmed) {
    //       if (await contratoStore.deleteContratoLivro(item.id)) {
    //         this.refreshDados();
    //       }
    //     }
    //   });
    // }
    async gerarAcessoAoPortal() {
        await userStore.createAlunoAcessoPortal(this.id);
        this.entityAlunoUser = await userStore.getUserByAlunoId(this.id);
    }
    resetFormAlterarSenhaAcessoPortal() {
        this.password1 = '';
        this.password2 = '';
        this.$validator.reset();
    }
    matriculaFinanceiroItems(financeiros, item) {
        return _.filter(financeiros, (i) => {
            if (i.contrato_livro_id == item.id) {
                if (i.financeiro_categoria.id_antigo == 105) {
                    return true;
                }
                else if (i.financeiro_categoria.id_antigo == 104) {
                    return item.pre_invoice_id == null;
                }
            }
        });
    }
    async submitFormAlterarSenhaAcessoPortal() {
        if (await this.$validator.validateAll()) {
            await mainStore.resetPassword({
                password: this.password1,
                email: '',
                user_id: this.entityAlunoUser.id,
            });
            this.dialogAlterarSenhaAcessoPortal = false;
            this.resetFormAlterarSenhaAcessoPortal();
        }
    }
    async desativarAcessoPortalAluno() {
        await userStore.desativarAlunoAcessoPortal(this.id);
        this.entityAlunoUser = await userStore.getUserByAlunoId(this.id);
    }
    async imprimirBoasVindas() {
        const html = await alunoStore.imprimirBoasVindas(this.id);
        const w = window.open();
        w.document.write(html);
        setTimeout(() => {
            w.print();
        }, 2500);
    }
    async confirmarEvasao(contrato) {
        this.$swal({
            title: 'Confirma a evasão do aluno?',
            text: 'Isso marcará o contrato do aluno como evasão. Essa ação é irreversível.',
            icon: 'warning',
            showCancelButton: true,
            cancelButtonText: 'Cancelar',
            confirmButtonText: 'Sim, eu confirmo!',
        }).then(async (result) => {
            if (result.isConfirmed) {
                this.loading = true;
                await contratoStore.contratoForcarEvasao(contrato.id);
                await this.handleCarregaContratoLivro();
                this.loading = false;
            }
        });
    }
    async ativarAcessoPortalAluno() {
        await userStore.ativarAlunoAcessoPortal(this.id);
    }
    async handleGerarFinanceiroLivro(item) {
        // @ts-ignore
        const form = this.$refs.GerarFinanceiroMaterialDidaticoDialog;
        form.show(item.id, item.contrato_id);
    }
    handleRegraGerarFinanceiro(item, contrato) {
        let temParcelaLivro = false;
        if (!contrato || !contrato.financeiro || contrato.financeiro.length === 0) {
            return false;
        }
        for (const financeiroItem of contrato.financeiro) {
            if (financeiroItem.contrato_livro_id === item.id) {
                if (financeiroItem.financeiro_categoria.id_antigo == 104) {
                    temParcelaLivro = true;
                }
            }
        }
        return !item.pre_invoice_id && !temParcelaLivro;
    }
    async handleCarregaContratoLivro() {
        const contrato = await contratoStore.getContratos(this.id);
        this.contratoItems = [];
        for (const item of contrato) {
            if (!item.curso) {
                item.curso = { nome: 'Curso não disponível' };
            }
            item.ponto_parada_ida = '';
            item.ponto_parada_volta = '';
            item.turmaAluno = await turmaStore.getTurmaAlunos({
                contrato_id: item.id,
                aluno_id: item.aluno_id,
            });
            if (item.consultor) {
                item.consultorSelected = {
                    tipo: 'Colaborador',
                    id: item.consultor.id,
                    nome: item.consultor.full_name,
                };
            }
            else {
                item.consultorSelected = null;
            }
            if (item.ponto_parada_ida_id) {
                item.ponto_parada_ida = await this.getPontoParada(item.ponto_parada_ida_id);
            }
            if (item.ponto_parada_volta_id) {
                item.ponto_parada_volta = await this.getPontoParada(item.ponto_parada_volta_id);
            }
            this.contratoItems.push(item);
        }
        this.showCardNovoContrato = false;
    }
    async mounted() {
        this.loading = true;
        await companyConfiguracaoDiaVencimentoParcelaStore.getCompanyConfiguracaoDiaVencimentoParcelas();
        if (this.userActionIncluirTransporte) {
            await pontoParadaStore.getPontosParada();
        }
        if (this.$router.currentRoute.params.id) {
            this.id = this.$router.currentRoute.params.id;
            // @ts-ignore
            this.transporteItems = await alunoStore.getTransportes(this.id);
            this.entityAlunoUser = await userStore.getUserByAlunoId(this.id);
            await this.handleCarregaContratoLivro();
            // @ts-ignore
            this.indicacaoItems = await alunoStore.getIndicacoes(this.id);
            this.entityObject = await alunoStore.getAluno(parseInt(this.$router.currentRoute.params.id, 0));
            if (this.entityObject.prospex_id) {
                const prospexIndb = {
                    nome: this.entityObject.aluno_nome,
                    id: this.entityObject.prospex_id,
                    tipo: 'Prospex',
                };
                this.atualizarDadosProspex = false;
                this.selectedProspex = prospexIndb;
            }
            this.contratoPicker = new Date().toISOString().split('T')[0];
            const d = new Date(this.entityObject.aluno_data_nascimento);
            const ageDifMs = Date.now() - d.getTime();
            const ageDate = new Date(ageDifMs); // miliseconds from epoch
            this.idade = Math.abs(ageDate.getUTCFullYear() - 1970);
            // nao precisa bater no viacep toda vez que abre a tela
            // if (this.entityObject.aluno_cep) {
            //   this.onChangeCep(this.entityObject.aluno_cep);
            // }
            // if (this.entityObject.responsavel_cep) {
            //   this.onChangeResponsavelCep(this.entityObject.responsavel_cep);
            // }
            if (this.entityObject.aluno_codigo_municipio_completo) {
                this.cidadeSelected = {
                    codigo_municipio_completo: this.entityObject
                        .aluno_codigo_municipio_completo,
                };
            }
            if (this.entityObject.cidade_aluno) {
                this.cidadeSelected = {
                    // @ts-ignore
                    name: this.entityObject.cidade_aluno.nome_municipio + ' - ' + this.entityObject.cidade_aluno.nome_uf,
                    // @ts-ignore
                    nome_municipio: this.entityObject.cidade_aluno.nome_municipio,
                    // @ts-ignore
                    nome_uf: this.entityObject.cidade_aluno.nome_uf,
                    // @ts-ignore
                    codigo_municipio_completo: this.entityObject.cidade_aluno.codigo_municipio_completo,
                };
            }
            if (this.entityObject.responsavel_codigo_municipio_completo) {
                this.responsavelCidadeSelected = {
                    codigo_municipio_completo: this.entityObject
                        .responsavel_codigo_municipio_completo,
                };
            }
            if (this.entityObject.cidade_responsavel) {
                this.responsavelCidadeSelected = {
                    // @ts-ignore
                    name: this.entityObject.cidade_responsavel.nome_municipio + ' - ' + this.entityObject.cidade_responsavel.nome_uf,
                    // @ts-ignore
                    nome_municipio: this.entityObject.cidade_responsavel.nome_municipio,
                    // @ts-ignore
                    nome_uf: this.entityObject.cidade_responsavel.nome_uf,
                    // @ts-ignore
                    codigo_municipio_completo: this.entityObject.cidade_responsavel.codigo_municipio_completo,
                };
            }
            if (!this.entityObject.aluno_contatos) {
                this.entityObject.aluno_contatos = [];
            }
        }
        else {
            this.entityObject = newAluno();
            if (this.$router.currentRoute.params.prospex_id) {
                const prospex = await prospexStore.getProspex(parseInt(this.$router.currentRoute.params.prospex_id, 0));
                this.idade = prospex.idade;
                this.entityObject.aluno_bairro = prospex.bairro;
                this.entityObject.aluno_celular = prospex.celular;
                if (!this.entityObject.aluno_contatos) {
                    this.entityObject.aluno_contatos = [];
                }
                // REMOVIDO POIS ACRESCENTEI O CAMPO aluno_celular
                // this.entityObject.aluno_contatos = [];
                // if (prospex.celular) {
                //   this.entityObject.aluno_contatos.push({
                //     phone: prospex.celular,
                //     operadora: prospex.operadora,
                //     observation: 'Celular do Aluno',
                //   });
                // }
                this.entityObject.aluno_codigo_municipio_completo =
                    prospex.codigo_municipio_completo;
                // @ts-ignore
                this.idioma[0] = prospex.curso_interesse[0];
                // @ts-ignore
                this.dias_preferencia = prospex.dias_preferencia;
                this.entityObject.aluno_email = prospex.email;
                if (prospex.fone_fixo) {
                    this.entityObject.aluno_contatos.push({
                        phone: prospex.fone_fixo,
                        operadora: '',
                        observation: 'Fone fixo do Aluno',
                    });
                }
                this.entityObject.aluno_nome = prospex.nome;
                if (prospex.responsavel) {
                    this.entityObject.aluno_responsavel_financeiro = false;
                    this.entityObject.responsavel_nome = prospex.responsavel;
                }
                const prospexIndb = {
                    nome: this.entityObject.aluno_nome,
                    id: prospex.id,
                    tipo: 'Prospex',
                };
                this.selectedProspex = prospexIndb;
            }
        }
        await livroStore.getLivros();
        await this.carregarCursos();
        // Orderna pra apresentar em Serviços Prestados
        _.forEach(this.contratoItems, (item) => {
            item.contrato_livro = _.orderBy(item.contrato_livro, ['id']);
        });
        if (this.entityObject.responsavel_cnpj) {
            this.tipoPessoaReponsavel = 'PJ';
        }
        else {
            this.tipoPessoaReponsavel = 'PF';
        }
        this.alunoCidadeLoading = false;
        this.responsavelCidadeLoading = false;
        this.loading = false;
    }
};
__decorate([
    Watch('selectedProspex')
], Create.prototype, "onChangeProspex", null);
__decorate([
    Watch('entityObject.aluno_cpf')
], Create.prototype, "onChangeEntityObjectAlunoCpf", null);
__decorate([
    Watch('entityObject.aluno_cep', { immediate: true, deep: true })
], Create.prototype, "onChangeCep", null);
__decorate([
    Watch('entityObject.aluno_data_nascimento')
], Create.prototype, "onChangeAlunoDataNascimento", null);
__decorate([
    Watch('entityObject.responsavel_cep', { immediate: true, deep: true })
], Create.prototype, "onChangeResponsavelCep", null);
Create = __decorate([
    Component({
        components: {
            AulaLocalShowButton,
            ClickableCard,
            ContratoAdicionarEstagioComponent,
            ContratoAlterarEstagioComponent,
            ContratoCancelarComponent,
            ContratoCriarComponent,
            ContratoExcluirComponent,
            ContratoLivroExcluirComponent,
            ContratoTrancarComponent,
            CrudArrayComponent,
            DateFieldComponent,
            EntregarLivroButton,
            FinanceiroAlunoComponent,
            FranchisePreInvoiceVisualizaComponent,
            GerarFinanceiroMaterialDidaticoDialog,
            LivroSolicitadoDiretoFranchiseButton,
            LogShowComponent,
            OcorrenciaComponent,
            OpenNewTabComponent,
            PedagogicoAlunoComponent,
            SaveButton,
            SearchCityComponent,
            SearchEntidadeComponent,
            SolicitarLivroParaFranchiseComponent,
            TextExpandableComponent,
            TurmaAdicionarAlunoComponent,
            TurmaEvadirAlunoComponent,
            UploadFilesComponent,
            ValidationObserver,
            ValidationProvider,
        },
    })
], Create);
export default Create;
