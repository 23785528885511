import { __decorate } from "tslib";
import { Vue, Component, Prop, Watch } from 'vue-property-decorator';
import { mainStore, contratoStore, cursoStore, livroStore, companyConfiguracaoCursoDescontoStore, companyConfiguracaoMaterialParcelamentoStore, companyConfiguracaoNegociacaoStore, companyConfiguracaoStore, pontoParadaStore, companyConfiguracaoDiaVencimentoParcelaStore, turmaStore, } from '@/store';
import ClickableCard from '@/components/ClickableCard.vue';
import { newContrato } from '@/interfaces/contrato';
import _ from 'lodash';
import DateFieldComponent from '@/components/DateFieldComponent.vue';
import SearchEntidadeComponent from '@/components/SearchEntidadeComponent.vue';
let ContratoCriarComponent = class ContratoCriarComponent extends Vue {
    constructor() {
        super(...arguments);
        this.cobrarTaxaMatriculaSelected = [];
        this.contratoItems = [];
        this.contratoPicker = '';
        this.cursoQuantidadeParcelasSelected = [];
        this.cursoSelected = {};
        this.cursoSelectedCard = [];
        this.dias_preferencia = [];
        this.diaSemanaSelected = [];
        this.diaVencimentoParcelaCursoSelected = [];
        this.diaVencimentoParcelaLivroSelected = [];
        this.e1 = 1;
        this.entityObjectContrato = null;
        this.idade = null;
        this.idioma = [];
        this.livroItems = [];
        this.livroSelected = null;
        this.livroIdiomaItems = [];
        this.materialDidaticoQuantidadeParcelasSelected = [];
        this.matriculaTipo = [];
        this.modalidadeSelected = [];
        this.duracaoContratoSelected = [];
        this.percentualDescontoSelected = [];
        this.periodoSelected = [];
        this.vendaMaterialTipoSelected = [];
        this.turmaSelected = [];
        this.turmas = [];
        this.loadindGerandoContrato = false;
        this.contratoConsultorSelected = null;
        this.alunoIndicou = null;
        this.taxaMatricula = 0;
        this.iraUtilizarTransporte = false;
        this.loadingPontosParada = true;
        this.contratoDataVencimentoPrimeiraParcelaCurso = null;
        this.valor_material_didatico = 0;
        this.cursoParcelas = null;
        this.materialDidaticoParcelaItems = [];
        this.alunoIndicouContratos = [];
        this.pontoParadaBuscaId = null;
        this.saveLoading = false;
        this.pontoParadaEntregaId = null;
        this.localAula = [
            'Aula at school',
            { id: 1, label: 'Aula at school', descricao: '' },
        ];
        this.livroHeaders = [
            {
                text: 'Livro',
                value: 'nome',
                align: 'left',
                filterable: true,
            },
            {
                text: 'Edição',
                value: 'edicao',
                align: 'left',
                filterable: true,
            },
            {
                text: '',
                value: 'actions',
                align: 'left',
                filterable: true,
            },
        ];
    }
    get livroByCursoIdioma() {
        return _.filter(livroStore.livros, (item) => {
            return (item.curso.idioma === this.idioma[0] &&
                !this.livroItems.includes(item));
        } // verificar a necessidade && item.edicao_vigente
        );
    }
    get cursoOptions() {
        if (this.idioma) {
            return _.filter(cursoStore.cursos, (item) => item.idioma === this.idioma[0]);
        }
    }
    get getTipoContrato() {
        return [
            { id: 1, label: 'Matrícula', descricao: 'Usado na primeira matrícula' },
            {
                id: 2,
                label: 'Rematrícula',
                descricao: 'Usado para renovar matrícula',
            },
        ];
    }
    get buscaCodigoTipoContrato() {
        if (this.matriculaTipo.length === 0) {
            return;
        }
        if (this.matriculaTipo[0] === 'Matrícula') {
            return 1;
        }
        if (this.matriculaTipo[0] === 'Rematrícula') {
            return 2;
        }
    }
    getLocalAula(tipo) {
        let locaisAula = [];
        if (this.userProfile.company.escola_id == 1) {
            locaisAula = [
                { id: 1, label: 'KNN at school', descricao: 'Aula na unidade' },
                { id: 2, label: 'KNN at home', descricao: 'Aula digital' },
            ];
        }
        else {
            locaisAula = [
                { id: 1, label: 'Phenom classroom', descricao: 'Aula na unidade' },
                { id: 2, label: 'Phenom connect', descricao: 'Aula digital' },
            ];
        }
        if (tipo) {
            return locaisAula.filter((local) => local.id === tipo);
        }
        return locaisAula;
    }
    get selectedLivrosNomes() {
        if (this.livroItems) {
            return this.livroItems.map((item) => item.nome);
        }
        return [];
    }
    get parcelamentoSelected() {
        if (this.entityObjectContrato) {
            const selected = _.find(this.companyConfiguracaoMaterialParcelamentos, (item) => item.numero_vezes ===
                this.entityObjectContrato.material_quantidade_parcelas);
            return selected;
        }
    }
    get companyConfiguracoes() {
        return companyConfiguracaoStore.companyConfiguracoes;
    }
    get companyConfiguracaoCursoDescontos() {
        return companyConfiguracaoCursoDescontoStore.companyConfiguracaoCursoDescontos;
    }
    get companyConfiguracaoDiaVencimentoParcelas() {
        return companyConfiguracaoDiaVencimentoParcelaStore.companyConfiguracaoDiaVencimentoParcelas;
    }
    get companyConfiguracaoMaterialParcelamentos() {
        return companyConfiguracaoMaterialParcelamentoStore.companyConfiguracaoMaterialParcelamentos;
    }
    get getDisableFinanceiroNextButton() {
        if (this.vendaMaterialTipoSelected[0] ==
            'Lançar financeiro do material' ||
            this.vendaMaterialTipoSelected.length == 0) {
            return (this.cobrarTaxaMatriculaSelected.length == 0 ||
                this.percentualDescontoSelected.length == 0 ||
                this.diaVencimentoParcelaCursoSelected.length == 0 ||
                this.cursoQuantidadeParcelasSelected.length == 0 ||
                this.diaVencimentoParcelaLivroSelected.length == 0 ||
                this.materialDidaticoQuantidadeParcelasSelected.length == 0);
        }
        else {
            return (this.cobrarTaxaMatriculaSelected.length == 0 ||
                this.percentualDescontoSelected.length == 0 ||
                this.diaVencimentoParcelaCursoSelected.length == 0 ||
                this.cursoQuantidadeParcelasSelected.length == 0);
        }
    }
    get sugestaoCursoPorIdade() {
        // @ts-ignore
        if (this.userProfile.company.escola_id === 1) {
            if (this.idioma[0] === 'Inglês') {
                if (this.idade >= 4 && this.idade <= 6) {
                    this.cursoSelected = { nome: 'Kinder', idioma: 'Inglês', id: 10 };
                    return 'Para crianças de 4 a 6 anos a formação ideal é a Kinder';
                }
                else if (this.idade >= 7 && this.idade <= 9) {
                    this.cursoSelected = { nome: 'Kids', idioma: 'Inglês', id: 9 };
                    return 'Para crianças de 7 a 9 anos o curso ideal é o Kids';
                }
                else if (this.idade >= 10 && this.idade <= 12) {
                    this.cursoSelected = { nome: 'Tweens', idioma: 'Inglês', id: 15 };
                    return 'Para pré-adolescentes de 10 a 12 anos o curso ideal é o Tweens';
                }
                else if (this.idade >= 13 && this.idade <= 15) {
                    this.cursoSelected = { nome: 'Teens', idioma: 'Inglês', id: 14 };
                    return 'Para adolescentes de 13 a 15 anos o curso ideal é o Teens';
                }
                else if (this.idade > 15) {
                    this.cursoSelected = { nome: 'Formação', idioma: 'Inglês', id: 5 };
                    return 'A partir dos 15 anos o curso ideal é o Formação';
                }
            }
            else if (this.idioma[0] === 'Espanhol') {
                if (this.idade >= 8 && this.idade <= 9) {
                    this.cursoSelected = { nome: 'Chiquitos', idioma: 'Espanhol', id: 3 };
                    return 'Para crianças de 8 a 9 anos a formação ideal é a Chiquitos';
                }
                else if (this.idade >= 10 && this.idade <= 12) {
                    this.cursoSelected = {
                        nome: 'Muchachos',
                        idioma: 'Espanhol',
                        id: 12,
                    };
                    return 'Para pré-adolescentes de 10 a 12 anos o curso ideal é o Muchachos';
                }
                else if (this.idade >= 13 && this.idade <= 15) {
                    this.cursoSelected = { nome: 'Jóvenes', idioma: 'Espanhol', id: 7 };
                    return 'Para adolescentes de 13 a 15 anos o curso ideal é o Jóvenes';
                }
                else if (this.idade > 15) {
                    this.cursoSelected = { nome: 'Espanhol', idioma: 'Espanhol', id: 4 };
                    return 'A partir dos 15 anos o curso ideal é o Espanhol';
                }
            }
            else if (this.idioma[0] === 'Alemão') {
                this.cursoSelected = { nome: 'Alemão', idioma: 'Alemão', id: 2 };
            }
            else if (this.idioma[0] === 'Francês') {
                this.cursoSelected = { nome: 'Francês', idioma: 'Francês', id: 6 };
            }
        }
        else {
            if (this.idioma[0] === 'Inglês') {
                if (this.idade >= 4 && this.idade <= 5) {
                    this.cursoSelected = {
                        nome: 'Discovering',
                        idioma: 'Inglês',
                        id: 16,
                    };
                    return 'Para crianças de 4 a 5 anos a formação ideal é a Discovering';
                }
                else if (this.idade >= 6 && this.idade <= 7) {
                    this.cursoSelected = { nome: 'Exploring', idioma: 'Inglês', id: 17 };
                    return 'Para crianças de 6 a 7 anos o curso ideal é o Exploring';
                }
                else if (this.idade >= 8 && this.idade <= 9) {
                    this.cursoSelected = { nome: 'Conquering', idioma: 'Inglês', id: 18 };
                    return 'Para crianças de 8 a 9 anos o curso ideal é o Conquering';
                }
                else if (this.idade >= 10 && this.idade <= 11) {
                    this.cursoSelected = { nome: 'Out There', idioma: 'Inglês', id: 19 };
                    return 'Para pré-adolescentes de 10 a 11 anos o curso ideal é o Out There';
                }
                else if (this.idade >= 12 && this.idade <= 14) {
                    this.cursoSelected = { nome: 'Moving Up', idioma: 'Inglês', id: 20 };
                    return 'Para adolescentes de 12 a 14 anos o curso ideal é o Moving Up';
                }
                else if (this.idade > 15) {
                    this.cursoSelected = { nome: 'Taking Off', idioma: 'Inglês', id: 21 };
                    return 'A partir dos 15 anos o curso ideal é o Taking Off';
                }
            }
            else if (this.idioma[0] === 'Espanhol') {
                this.cursoSelected = {
                    nome: 'Espanhol básico',
                    idioma: 'Inglês',
                    id: 22,
                };
            }
            else if (this.idioma[0] === 'Francês') {
                this.cursoSelected = {
                    nome: 'Francês básico',
                    idioma: 'Francês',
                    id: 24,
                };
            }
        }
    }
    get userProfile() {
        return mainStore.userProfile;
    }
    get getStepIndicacao() {
        return this.userProfile.company.escola_id === 2 ? 4 : 5;
    }
    get getStepResumo() {
        return this.userProfile.company.escola_id === 2 ? 5 : 6;
    }
    get turmasOptions() {
        if (this.livroIdiomaItems[0]) {
            return _.filter(this.turmas, (item) => {
                // @ts-ignore
                if (item.status_id !== 4) {
                    // Extrai a hora do horário
                    const diaSemana = item.cronograma_horario.length > 0
                        ? item.cronograma_horario[0].dia_semana
                        : null;
                    const hora = item.cronograma_horario.length > 0
                        ? parseInt(item.cronograma_horario[0].horario.split(':')[0], 0)
                        : null;
                    let periodo = 'Manhã';
                    if (hora >= 12 && hora < 18) {
                        periodo = 'Tarde';
                    }
                    else if (hora >= 18 && hora <= 24) {
                        periodo = 'Noite';
                    }
                    return (
                    // @ts-ignore Remover para conseguir inserir inicialmente pelos outros estagios
                    item.livro === this.livroItems[0].nome &&
                        this.periodoSelected.indexOf(periodo) > -1 &&
                        this.diaSemanaSelected.indexOf(diaSemana) > -1);
                }
            });
        }
        else {
            return false;
        }
    }
    removeLivro(item) {
        const idx = this.livroItems.indexOf(item);
        // @ts-ignore
        this.livroItems.splice(idx, 1);
    }
    async atualizaDados(deveAtualizarLivroIdiomas = false) {
        const val = this.cursoSelectedCard[0];
        const curso = _.find(this.cursoOptions, ['nome', val]);
        const curso_id = curso.id;
        if (deveAtualizarLivroIdiomas) {
            this.livroIdiomaItems = _.filter(livroStore.livros, (item) => item.curso_id === curso_id && item.edicao_vigente === true);
            this.livroItems = _.orderBy(this.livroIdiomaItems, 'curso_etapa_id');
        }
        if (this.vendaMaterialTipoSelected.length > 0) {
            if (this.vendaMaterialTipoSelected[0] ==
                'Não lançar financeiro do material') {
                this.valor_material_didatico = 0;
            }
        }
        this.cursoParcelas =
            // @ts-ignore
            this.livroItems.length * this.modalidadeSelected[0].quantidade_parcela;
    }
    async onVendaMaterialTipoSelected(val) {
        if (val.length > 0) {
            await this.atualizaDados();
        }
    }
    async onChangeModalidade(val) {
        if (val) {
            if (val.length > 0 && this.cursoSelectedCard.length > 0) {
                await this.atualizaDados();
            }
        }
    }
    async onChangeCurso(val) {
        if (val && this.cursoSelectedCard[0]) {
            await this.atualizaDados(true);
            this.turmas = await turmaStore.getTurmas({
                keyword: '',
                status_id: [1, 2],
                curso_nome: this.cursoSelectedCard[0],
            });
        }
    }
    async onChangeDiaVencimentoParcelaLivroSelected(val) {
        if (val.length > 0) {
            await this.atualizaDados();
        }
    }
    async onChangeDiaVencimentoParcelaCursoSelected(val) {
        if (val.length > 0) {
            await this.atualizaDados();
        }
    }
    // @Watch('alunoIndicou')
    // public async onChangeAlunoIndicou(val, olVal) {
    //   if (val) {
    //     this.alunoIndicouContratos = await contratoStore.getContratos(
    //      this.alunoIndicou.id
    //     );
    //   }
    // }
    async onChangeMaterialDidaticoQuantidadeParcelasSelected(val) {
        if (val.length > 0) {
            const numero_vezes = parseInt(val[0].replace('x', ''), 0);
            this.valor_material_didatico = _.find(this.companyConfiguracaoMaterialParcelamentos, (item) => item.numero_vezes === numero_vezes);
            let arr = [];
            const valorParcela = 
            // @ts-ignore
            this.valor_material_didatico.valor_integral / numero_vezes;
            // @ts-ignore
            arr = new Array(parseInt(val, 0)).fill().map((e, i) => {
                return { parcela: i + 1, valor: valorParcela, vencimento: new Date() };
            });
            this.materialDidaticoParcelaItems = arr;
            await this.atualizaDados();
        }
    }
    async onClickRequisitouTransporte(item) {
        if (!item.requisitou_transporte) {
            item.ponto_parada_ida_id = null;
            item.ponto_parada_volta_id = null;
            await contratoStore.updateContrato(item);
            this.contratoItems = await contratoStore.getContratos(this.propAlunoId);
            _.forEach(this.contratoItems, async (i) => {
                if (i.ponto_parada_ida_id) {
                    i.ponto_parada_ida = await this.getPontoParada(i.ponto_parada_ida_id);
                }
                if (i.ponto_parada_volta_id) {
                    i.ponto_parada_volta = await this.getPontoParada(i.ponto_parada_volta_id);
                }
            });
        }
    }
    getCardSelectedItemsString(selected) {
        if (selected) {
            return selected.join(', ');
        }
        return '';
    }
    async getPontoParada(id) {
        const ponto = await pontoParadaStore.getPontoParada(id);
        if (ponto) {
            return ponto;
        }
    }
    apresentaPontosParada() {
        this.iraUtilizarTransporte = true;
        this.loadingPontosParada = true;
        pontoParadaStore.getPontosParada();
        this.loadingPontosParada = false;
    }
    onVoltarSelecionarTurma() {
        this.turmaSelected = [];
        this.e1 = this.e1 - 1;
    }
    async onSaveEditContratoTransporte(item) {
        await contratoStore.updateContrato(item);
        this.contratoItems = await contratoStore.getContratos(this.propAlunoId);
        // Orderna pra apresentar em Serviços Prestados
        _.forEach(this.contratoItems, (i) => {
            i.contrato_livro = _.orderBy(i.contrato_livro, ['id']);
        });
        _.forEach(this.contratoItems, async (i) => {
            if (i.ponto_parada_ida_id) {
                i.ponto_parada_ida = await this.getPontoParada(i.ponto_parada_ida_id);
            }
            if (i.ponto_parada_volta_id) {
                i.ponto_parada_volta = await this.getPontoParada(i.ponto_parada_volta_id);
            }
        });
    }
    async validateEtapa5() {
        // @ts-ignore
        const valid = await this.$refs.searchEntidadeComponentConsultor.validateField();
        if (valid) {
            this.e1++;
        }
    }
    adicionarLivro() {
        let finalArr = [...this.livroItems];
        // @ts-ignore
        finalArr.push(this.livroSelected);
        const livrosCursoIndexed = {};
        for (const item of finalArr) {
            if (item.curso.ordem in livrosCursoIndexed) {
                livrosCursoIndexed[item.curso.ordem].push(item);
                continue;
            }
            livrosCursoIndexed[item.curso.ordem] = [item];
        }
        const keys = Object.keys(livrosCursoIndexed);
        keys.sort();
        finalArr = [];
        for (const key of keys) {
            finalArr.push(_.orderBy(livrosCursoIndexed[key], ['curso_etapa_id'], ['asc']));
        }
        this.livroItems = _.flattenDeep(finalArr);
        this.livroSelected = null;
    }
    async gerarContrato() {
        this.loadindGerandoContrato = true;
        this.entityObjectContrato = newContrato();
        let curso_id = _.find(this.cursoOptions, (item) => item.nome === this.cursoSelectedCard[0]);
        if (this.contratoConsultorSelected) {
            this.entityObjectContrato.consultor_id =
                this.contratoConsultorSelected.id;
        }
        if (this.alunoIndicou) {
            this.entityObjectContrato.indicou_aluno_id = this.alunoIndicou.id;
        }
        curso_id = curso_id.id;
        // @ts-ignore
        this.entityObjectContrato.curso_id = curso_id;
        this.entityObjectContrato.data_primeira_parcela_curso =
            this.contratoDataVencimentoPrimeiraParcelaCurso;
        this.entityObjectContrato.preferencia_dia_semana = this.diaSemanaSelected;
        this.entityObjectContrato.preferencia_periodo = this.periodoSelected;
        if (this.cobrarTaxaMatriculaSelected[0] === 'Isento') {
            this.entityObjectContrato.taxa_matricula_valor = 0;
        }
        else {
            this.entityObjectContrato.taxa_matricula_valor = this.taxaMatricula;
        }
        if (this.vendaMaterialTipoSelected[0] ==
            'Lançar financeiro do material' ||
            this.vendaMaterialTipoSelected.length == 0) {
            this.entityObjectContrato.material_didatico_dia_vencimento =
                this.diaVencimentoParcelaLivroSelected[0].replace('Dia ', '');
            this.entityObjectContrato.material_quantidade_parcelas = parseInt(this.materialDidaticoQuantidadeParcelasSelected[0].replace('x', ''), 0);
        }
        this.entityObjectContrato.curso_quantidade_parcelas = parseInt(this.cursoQuantidadeParcelasSelected[0].replace('x', ''), 0);
        let valorEstagio = 0;
        switch (this.modalidadeSelected[0].toLowerCase()) {
            case 'regular':
                // @ts-ignore
                valorEstagio = this.companyConfiguracoes.estagio_valor_regular;
                break;
            case 'vip':
                // @ts-ignore
                valorEstagio = this.companyConfiguracoes.estagio_valor_vip;
                break;
            case 'intensivo':
                // @ts-ignore
                valorEstagio = this.companyConfiguracoes.estagio_valor_intensivo;
                break;
            case 'semi-intensivo':
                // @ts-ignore
                valorEstagio = this.companyConfiguracoes.estagio_valor_semi_intensivo;
                break;
            case 'executive':
                // @ts-ignore
                valorEstagio = this.companyConfiguracoes.estagio_valor_executive;
                break;
        }
        this.entityObjectContrato.curso_valor_integral =
            this.livroItems.length * valorEstagio;
        // confirmar se faz sentido ou precisa mudar a precificacao para curso ao inves de estagio
        this.entityObjectContrato.curso_desconto_percentual = parseFloat(this.percentualDescontoSelected[0].replace('%', ''));
        if (this.vendaMaterialTipoSelected[0] ==
            'Lançar financeiro do material' ||
            this.vendaMaterialTipoSelected.length == 0) {
            this.entityObjectContrato.material_didatico_valor_integral =
                this.livroItems.length * this.parcelamentoSelected.valor_integral;
            this.entityObjectContrato.material_didatico_desconto_percentual = 0;
        }
        else {
            this.entityObjectContrato.material_didatico_valor_integral = null;
            this.entityObjectContrato.material_didatico_desconto_percentual = null;
        }
        this.entityObjectContrato.curso_dia_vencimento = parseInt(this.diaVencimentoParcelaCursoSelected[0].replace('Dia ', ''), 0);
        this.entityObjectContrato.modalidade_id = _.find(
        // @ts-ignore
        this.cursoModalidadeOptions(), (item) => item.nome === this.modalidadeSelected[0]).id;
        this.entityObjectContrato.aluno_id = parseInt(this.propAlunoId, 0);
        if (this.turmaSelected.length > 0) {
            this.entityObjectContrato.turma_id = this.turmaSelected[1].id;
        }
        this.entityObjectContrato.requisitou_transporte =
            this.iraUtilizarTransporte;
        this.entityObjectContrato.ponto_parada_ida_id = this.pontoParadaBuscaId;
        this.entityObjectContrato.ponto_parada_volta_id = this.pontoParadaEntregaId;
        this.entityObjectContrato.vigencia_inicio = this.contratoPicker;
        this.entityObjectContrato.servicos_prestados = this.livroItems;
        this.entityObjectContrato.quantidade_meses_por_estagio = _.find(
        // @ts-ignore
        this.cursoModalidadeOptions(), (item) => item.nome === this.modalidadeSelected[0]).quantidade_meses_por_estagio;
        if (this.duracaoContratoSelected && this.duracaoContratoSelected.length > 0) {
            this.entityObjectContrato.quantidade_meses_por_estagio = this.duracaoContratoSelected[1].id;
        }
        this.entityObjectContrato.company_id = this.userProfile.company.id;
        this.entityObjectContrato.matricula_tipo_id = this.matriculaTipo[1].id;
        this.entityObjectContrato.local_aula_id = 1;
        if (this.userProfile.company.aula_online_allow) {
            // @ts-ignore
            this.entityObjectContrato.local_aula_id = this.localAula[1].id;
        }
        await contratoStore.createContrato(this.entityObjectContrato);
        this.e1 = 1;
        this.$emit('on-confirm');
        this.loadindGerandoContrato = false;
    }
    async mounted() {
        const promise1 = companyConfiguracaoDiaVencimentoParcelaStore.getCompanyConfiguracaoDiaVencimentoParcelas();
        const promise2 = companyConfiguracaoCursoDescontoStore.getCompanyConfiguracaoCursoDescontos();
        const promise3 = companyConfiguracaoMaterialParcelamentoStore.getCompanyConfiguracaoMaterialParcelamentos();
        const promise4 = companyConfiguracaoNegociacaoStore.getCompanyConfiguracaoNegociacoes();
        const promise5 = companyConfiguracaoStore.getCompanyConfiguracoes();
        const promise6 = livroStore.getLivros();
        await Promise.all([promise1, promise2, promise3, promise4, promise5, promise6]);
        this.contratoPicker = new Date().toISOString().split('T')[0];
        this.contratoDataVencimentoPrimeiraParcelaCurso = new Date()
            .toISOString()
            .split('T')[0];
        // @ts-ignore
        this.taxaMatricula = this.companyConfiguracoes.matricula_valor;
        // this.showCardNovoContrato = true;
        if (this.propProspex) {
            if (this.propProspex.indicou_aluno_id && this.propProspex.indicou_aluno_nome) {
                this.alunoIndicou = {
                    tipo: 'Aluno',
                    id: this.propProspex.indicou_aluno_id,
                    nome: this.propProspex.indicou_aluno_nome,
                    idade: null,
                    parceria_nome: null
                };
            }
        }
    }
};
__decorate([
    Prop({ type: Number, required: true })
], ContratoCriarComponent.prototype, "propAlunoId", void 0);
__decorate([
    Prop()
], ContratoCriarComponent.prototype, "propProspex", void 0);
__decorate([
    Watch('vendaMaterialTipoSelected', { immediate: false, deep: true })
], ContratoCriarComponent.prototype, "onVendaMaterialTipoSelected", null);
__decorate([
    Watch('modalidadeSelected', { immediate: false, deep: true })
], ContratoCriarComponent.prototype, "onChangeModalidade", null);
__decorate([
    Watch('cursoSelectedCard', { immediate: false, deep: true })
], ContratoCriarComponent.prototype, "onChangeCurso", null);
__decorate([
    Watch('diaVencimentoParcelaLivroSelected', { immediate: false, deep: true })
], ContratoCriarComponent.prototype, "onChangeDiaVencimentoParcelaLivroSelected", null);
__decorate([
    Watch('diaVencimentoParcelaCursoSelected', { immediate: false, deep: true })
], ContratoCriarComponent.prototype, "onChangeDiaVencimentoParcelaCursoSelected", null);
__decorate([
    Watch('materialDidaticoQuantidadeParcelasSelected')
], ContratoCriarComponent.prototype, "onChangeMaterialDidaticoQuantidadeParcelasSelected", null);
ContratoCriarComponent = __decorate([
    Component({
        components: {
            ClickableCard,
            DateFieldComponent,
            SearchEntidadeComponent,
        },
    })
], ContratoCriarComponent);
export default ContratoCriarComponent;
