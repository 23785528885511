import { __decorate } from "tslib";
import { Vue, Component, Prop } from 'vue-property-decorator';
import _ from 'lodash';
import { livroStore, contratoStore } from '@/utils/store-accessor';
import ClickableCard from '@/components/ClickableCard.vue';
let ContratoAlterarEstagioComponent = class ContratoAlterarEstagioComponent extends Vue {
    constructor() {
        super(...arguments);
        this.livroSelected = [];
        this.exibeTodosEstagios = false;
        this.searchLivro = '';
    }
    onCancel() {
        this.livroSelected = [];
        this.$emit('on-cancel');
    }
    get livroByCursoIdioma() {
        if (this.exibeTodosEstagios) {
            return livroStore.livros;
        }
        else {
            if (this.propContratoLivroItem) {
                return _.filter(livroStore.livros, (item) => item.curso.id === this.propCursoId &&
                    this.propContratoLivroItem.livro_id !== item.id);
            }
        }
    }
    get filteredLivros() {
        if (!this.searchLivro) {
            return this.livroByCursoIdioma;
        }
        return this.livroByCursoIdioma.filter((livro) => livro.nome.toLowerCase().includes(this.searchLivro.toLowerCase()));
    }
    async exibirTodosEstagios() {
        this.exibeTodosEstagios = !this.exibeTodosEstagios;
    }
    async alterarEstagio() {
        const obj = {
            contrato_livro_id: this.propContratoLivroItem.id,
            // @ts-ignore
            novo_livro_id: this.livroSelected[1].id,
            // @ts-ignore
            financeiro_observacao: this.livroSelected[1].nome,
            contrato_id: this.propContratoLivroItem.contrato_id,
        };
        await contratoStore.alterarEstagio(obj);
        this.onCancel();
    }
};
__decorate([
    Prop({ type: Object })
], ContratoAlterarEstagioComponent.prototype, "propContratoLivroItem", void 0);
__decorate([
    Prop({ type: Number })
], ContratoAlterarEstagioComponent.prototype, "propCursoId", void 0);
ContratoAlterarEstagioComponent = __decorate([
    Component({
        components: {
            ClickableCard,
        },
    })
], ContratoAlterarEstagioComponent);
export default ContratoAlterarEstagioComponent;
